<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: cultureActionDialog.vue
Description: This file contains UI components of dialog form for adding culture
-->
<template>
  <er-dialog
    width="28%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @beforeClose="handleCloseDialog('close')"
    :title="
      actionToPerform === 'ACTIVE'
        ? $tc('Comn_culture.capitalize', 1)
        : $t('Comn_current_culture')
    "
    class="action-on-pond"
    custom-class="cultureAction_dialog"
  >
    <el-alert
      v-if="ehm__unhandledErrorMessage"
      :title="ehm__unhandledErrorMessage"
      type="error"
      @close="ehm__handleAlertClose"
    ></el-alert>

    <el-form size="small" :model="formPond" @submit.prevent="submitPondDetails">
      <el-form-item
        :label="formStrings.title.label"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.title)"
      >
        <el-col :span="24">
          <el-input
            class="input-pond-name"
            maxlength="50"
            v-model="formPond.title"
            :placeholder="formStrings.title.placeholder"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        :label="formStrings.size.label"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.size)"
      >
        <el-col :span="24">
          <el-row type="flex" class="row-size">
            <el-col :span="12">
              <el-input
                type="number"
                class="input-pond-size"
                :precision="2"
                :value="parseFloat(Number(formPond.size).toFixed(2))"
                :controls="false"
                :min="0"
                :max="1000"
                @input="handleChangeInArea"
              ></el-input> </el-col
            >&nbsp;&nbsp;
            <span>
              <er-select
                :value="formPond.units"
                :controls="false"
                :min="0"
                class="select-size-units"
                @change="handleChangeAreaUnits"
              >
                <el-option
                  v-for="(unit, key) in areaunits"
                  :key="unit"
                  :label="unit"
                  :value="key"
                ></el-option>
              </er-select>
            </span>
          </el-row>
          <div class="error-msg" v-if="isError">{{$t('Pond_size_error_msg', { maxArea: this.maxArea, minArea: this.minArea })}}</div>
        </el-col>
      </el-form-item>
      <el-form-item
        :label="formStrings.cultivation_date.label"
        :error="
          ekmm__castErrorKeyToLang(ehm__errMessagesObject.cultivation_date)
        "
      >
        <el-col :span="24">
          <el-date-picker
            class="date-picker-stock-date"
            v-model="formPond.cultivation_date"
            type="date"
            value-format="yyyy-MM-dd"
            :format="this.upm__getFormatDateString"
            :clearable="false"
            :picker-options="pickerOptions"
            :placeholder="formStrings.cultivation_date.placeholder"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item
        :label="formStrings.post_larva_stocked.label"
        :error="
          ekmm__castErrorKeyToLang(ehm__errMessagesObject.post_larva_stocked)
        "
      >
        <el-col :span="24">
          <el-input
            type="number"
            :min="1"
            title
            class="input-pl-stocked"
            :controls="false"
            v-model.number="formPond.post_larva_stocked"
            @change="handleChangePlStock"
            :placeholder="formStrings.post_larva_stocked.placeholder"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="formStrings.initial_abw.label"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.initial_abw)"
      >
        <el-input-number
          v-model="formPond.initial_abw"
          :placeholder="$t('initial_abw')"
          :controls="false"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label="formStrings.hatchery_name.label"
      >
        <er-input
          v-model="formPond.hatchery_name"
          :placeholder="$t('Pond_hatchery_name')"
          :disabled="actionToPerform !== 'ACTIVE'"
        ></er-input>
      </el-form-item>
    </el-form>

    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="submitPondDetails"
        :disabled="isReadOnly || isError"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog('close')"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { mapGetters } from "vuex";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import * as IntervalTree from "@davidisaaclee/interval-tree";
export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  props: {
    pond: {
      default: {
        type: "",
        name: "",
        feed_type: "",
        kg_dispensed_time: 0,
        status: "",
        units: ""
      }
    },
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    }
  },
  data: function() {
    return {
      formPond: this.pond,
      loading: false,
      hoc: "",
      newErrorMessageObject: {
        title: "",
        size: "",
        units: "",
        pond_id: "",
        cultivation_date: "",
        post_larva_stocked: "",
        initial_abw: ""
      },
      ehm__errMessagesObject: {
        title: "",
        size: "",
        units: "",
        pond_id: "",
        cultivation_date: "",
        post_larva_stocked: "",
        initial_abw: ""
      },
      ekmm__backndfieldToFieldNameMap: {
        title: "Comn_pond_name",
        size: "Comn_size",
        post_larva_stocked: "Pond_pls_stocked",
        initial_abw: "Comn_initial_abw",
        cultivation_date: "Comn_stocking_date"
      },
      ekmm__backndfieldToInvalidMap: {
        size: { INVALID_VALUE: "Map_pond_size_greater0" },
        post_larva_stocked: {
          INVALID_VALUE: "Comn_pls_count_not_empty",
          VALUE_SHOULD_BE_A_SAFE_NUMBER: 1000000000000
        },
        initial_abw: {
          INVALID_VALUE: "Comn_initial_abw_required",
          VALUE_SHOULD_BE_NUMBER: "Comn_initial_abw_greater_than_zero"
        },
        title: {
          FIELD_REQUIRED: "Comn_title_required"
        },
        cultivation_date: {
          INVALID_VALUE: "ABW_date_field_not_empty",
          DATE_SHOULD_BE_GREATER_THAN_PAST_CULTIVATION_DATE:
            "Date_should_be_greater_than_past_cultivation_date"
        }
      },
      isError: false,
      actualPondSizeAC: 0,
      actualPondSizeHA: 0,
      minArea: 0,
      maxArea: this.maxArea,
    };
  },

  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    isReadOnly() {
      return this.$store.getters["user/isReadOnly"];
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    areaunits: function() {
      return {
        ha: this.$t("Pond_size_units_ha"),
        ac: this.$t("Pond_size_units_ac")
      };
    },
    formStrings() {
      return {
        status: {
          label: this.$t("Comn_status"),
          placeholder: this.$t("Comn_status")
        },
        post_larva_stocked: {
          label: this.$t("Pond_pls_stocked"),
          placeholder: this.$t("Pond_pls_stocked")
        },
        title: {
          label: this.$t("Comn_pond_name"),
          placeholder: this.$t("Comn_pond_name")
        },
        size: {
          label: this.$tc("Pond_size", 1),
          placeholder: this.$tc("Pond_size", 1)
        },
        cultivation_date: {
          label: this.$t("Comn_stocking_date"),
          placeholder: this.$t("Comn_stocking_date")
        },
        units: {
          placeholder: this.$t("Comn_units")
        },
        initial_abw: {
          label: this.$t("initial_abw"),
          placeholder: this.$t("initial_abw")
        },
        hatchery_name: {
          label: this.$t("Pond_hatchery_name"),
          placeholder: this.$t("Pond_hatchery_name")
        }
      };
    },
    getCultivationIntervalTree() {
      let cultivationIntervalTree = IntervalTree.empty;
      const { cultivations = [] } = this.formPond;
      cultivationIntervalTree = cultivations
        .map((eachCultivation) => {
          return {
            date_range: ["start_date", "end_date"].map((currKey) => {
              return this.dhm__dateUtilsLib.getTime(
                this.dhm__dateUtilsLib.parse(
                  eachCultivation[currKey],
                  this.dhm__dateUtilsLib.isoFormatString,
                  new Date()
                )
              );
            }),
            _id: eachCultivation.harvest_id
          };
        })
        .reduce((tree, cultivationObj) => {
          return IntervalTree.insert(
            {
              id: cultivationObj._id,
              range: {
                low: cultivationObj.date_range[0],
                high: cultivationObj.date_range[1]
              }
            },
            tree
          );
        }, cultivationIntervalTree);
      return cultivationIntervalTree;
    },
    pickerOptions: function() {
      const { cultivations = [] } = this.formPond;
      return {
        disabledDate: (time) => {
          if (cultivations.length === 0) {
            return time.getTime() > Date.now();
          }
          const lastCultivation = cultivations[cultivations.length - 1];
          const lastCultivationEndDate = this.dhm__dateUtilsLib.getTime(
            this.dhm__dateUtilsLib.parse(
              lastCultivation.end_date,
              this.dhm__dateUtilsLib.isoFormatString,
              new Date()
            )
          );
          return (
            lastCultivationEndDate >= time.getTime() ||
            time.getTime() > Date.now()
          );
        }
      };
    }
  },
  watch: {
  "formPond.size"(newVal, oldVal) {
      if (parseFloat(oldVal) === 0) {
        if (!this.actualPondSizeAC > 0) {
          if (this.getPreferredUnits.pond_area_units === 'ha') {
            this.actualPondSizeAC = parseFloat((newVal * 2.47105).toFixed(3))
          }
          if (this.getPreferredUnits.pond_area_units === 'ac') {
            this.actualPondSizeAC = parseFloat(newVal.toFixed(3))
          }
        }
      }
      this.actualPondSizeHA = parseFloat((this.actualPondSizeAC / 2.47105).toFixed(3));
    }
  },
  methods: {
    // ekmm__errorLangKeyMapper: function (key, error_code) {
    //   if (error_code === "DATE_SHOULD_BE_GREATER_THAN_PAST_CULTIVATION_DATE") {
    //     return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
    //   }
    // },
    handleChangeInArea(value) {
      if (value < 0) {
        this.formPond.size = 0;
      } else {
        if (value >= 1000) {
          this.formPond.size = "";
        }
        this.formPond.size = parseFloat(value);
        // if (this.formPond.units === 'ha') {
        // this.checkMaximumPondSize(Number(value), this.actualPondSizeHA)
        // } else {
        //   this.checkMaximumPondSize(Number(value), this.actualPondSizeAC)
        // }
      }
    },
    checkMaximumPondSize(value, actualPondSize) {
      const minArea = parseFloat((actualPondSize - (actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      const maxArea = parseFloat((actualPondSize + (actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      console.log("actualPondSize", actualPondSize, value, maxArea, minArea);
      if (parseFloat(value.toFixed(2).slice(0, -1)) > maxArea || parseFloat(value.toFixed(2).slice(0, -1)) < minArea) {
        this.isError = true;
        this.minArea = minArea;
        this.maxArea = maxArea;
      } else {
        this.isError = false;
      }
      return this.maxArea;
    },
    handleDialogOpen() {
      this.isError = false;
      this.actualPondSizeAC = 0;
      this.actualPondSizeHA = 0;
      this.minArea = 0;
      this.formPond = this.pond;
      console.log(this.formPond, Date.now(), this.actionToPerform);
      const cultivations = this.formPond.cultivations;
      this.hoc = this.formPond.cultivations.length
        ? this.dhm__formatByTZOnUTCWithLocale(
            this.formPond.cultivations[cultivations.length - 1].end_date,
            "yyyy-MM-dd"
          )
        : "-";
      this.formPond.initial_abw = this.formPond.initial_abw_id
        ? this.formPond.initial_abw_id.abw
        : 0.1;
      const date = this.dhm__formatTZ(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCDateObj(this.dhm__getTodayInUserTZ),
          this.getUserTimeZoneString
        ),
        "yyyy-MM-dd"
      );
      this.formPond.cultivation_date = this.$lodash.get(
        this.formPond,
        "cultivation_date",
        date
      );
      if (this.actionToPerform === "ACTIVE") {
        this.formPond.post_larva_stocked = 0;
        this.formPond.cultivation_date = this.dhm__formatTZ(
          this.dhm__getTodayInUserTZ,
          "yyyy-MM-dd"
        );
      }

      this.formPond.size = +Number(
        this.upm__applyConversion(
          +this.formPond.size,
          this.formPond.units,
          this.getPreferredUnits.pond_area_units
        )
      );
      this.formPond.units = this.getPreferredUnits.pond_area_units;
    },
    handleCloseDialog(event) {
      this.ehm__errMessagesObject = this.$lodash.cloneDeep(
        this.newErrorMessageObject
      );
      this.formPond.size = 0;
      this.$emit("close_dialog", event);
    },
    handleChangeAreaUnits(newUnitsSelected) {
      this.formPond.size = +this.$commonUtils.castPondAreaSize(
        this.formPond.size,
        this.formPond.units,
        newUnitsSelected
      );
      // if (newUnitsSelected === 'ha') {
      //   this.checkMaximumPondSize(this.formPond.size, this.actualPondSizeHA)
      // } else {
      //   this.checkMaximumPondSize(this.formPond.size, this.actualPondSizeAC)
      // }
      this.formPond.units = newUnitsSelected;
    },
    ehm__error409Handler: function(err) {
      if (err.response.data.error_code === "POND_TITLE_ALREADY_TAKEN") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Ponds_name_already_in_use_choose_different"),
          type: "error",
          duration: 4500
        });
      } else {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong"),
          type: "error",
          duration: 4500
        });
      }
    },
    handleChangePlStock(value) {
      if (!+value) {
        this.formPond.post_larva_stocked = 0;
      }
    },
    async submitPondDetails() {
      try {
        this.ehm__errMessagesObject = {
          title: "",
          size: "",
          units: "",
          pond_id: "",
          cultivation_date: "",
          post_larva_stocked: "",
          initial_abw: ""
        };

        this.loading = true;
        // this.formPond.cultivation_date += "T00:00:00.000Z";
        let {
          title,
          size,
          units,
          pond_id,
          cultivation_date,
          post_larva_stocked,
          initial_abw,
          hatchery_name
        } = this.formPond;
        post_larva_stocked = +post_larva_stocked;
        cultivation_date = cultivation_date.substring(0, 10);
        cultivation_date = cultivation_date + "T00:00:00.000Z";
        let storeAction = null;
        let payload = null;
        storeAction = "pond/updatePondDetails";
        payload = {
          title: this.upm__trimSpacesInStr(title),
          size,
          units,
          pond_id,
          cultivation_date,
          post_larva_stocked,
          initial_abw,
          hatchery_name: hatchery_name !== '' ? hatchery_name : undefined,
          status: "ACTIVE"
        };
        const errorgenerator = (error_code, error_key) => {
          throw {
            response: {
              status: 422,
              data: {
                errors: {
                  details: [{ error_code: error_code, key: error_key }]
                }
              }
            }
          };
        };
        if (this.formPond.initial_abw === undefined) {
          errorgenerator("INVALID_VALUE", "initial_abw");
        } else if (this.formPond.initial_abw <= 0) {
          errorgenerator("VALUE_SHOULD_BE_NUMBER", "initial_abw");
        } else if (this.formPond.cultivation_date === this.hoc) {
          errorgenerator(
            "DATE_SHOULD_BE_GREATER_THAN_PAST_CULTIVATION_DATE",
            "cultivation_date"
          );
        } else {
          await this.$store.dispatch(storeAction, payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t(
              this.actionToPerform === "ACTIVE"
                ? "Comn_pond_activated_successfully"
                : "Pond_data_upd_success",
              { pond_name: this.upm__trimSpacesInStr(title) }
            ),
            duration: 5000,
            type: "success"
          });
          this.handleCloseDialog("pond-action-" + this.actionToPerform);
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.action-on-pond {
  $width: 200px;
  .el-dialog__body {
    padding: 15px 15px;
  }
  .input-pond-name {
    width: $width !important;
  }
  .row-size {
    position: relative;
    width: $width;
    .select-size-units {
      .el-input__inner {
        padding-right: 26px;
        @include responsiveProperty(width, 100px, 120px, 140px);
      }

      width: unset;
      box-sizing: border-box;
      position: relative;
    }
    .input-pond-size {
      width: unset;
      box-sizing: border-box;
      position: relative;
      .el-input__inner {
        width: 100px;
      }
    }
  }
  .input-pl-stocked {
    width: $width !important;
    .el-input__inner {
      text-align: left !important;
    }
  }
  .date-picker-stock-date {
    width: $width !important;
    .el-input__inner {
      text-align: left;
    }
  }
}

.cultureAction_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 205px, 227px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(width, 120px, 140px, 180px);
    @include responsiveProperty(min-width, 110px, 140px, 200px);
  }
  .error-msg {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: -10px;
  }
}
</style>
