<template>
  <er-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog"
    @open="handleOpenDialog"
    :title="`${$t('Comn_update')} ${$t('Comn_pond_mother')}`"
    class="pondmother_container"
    custom-class="pondmother_action_dialog"
  >
    <ValidationObserver ref="dialogListForm">
      <el-form
        size="small"
        v-loading="loading"
        :model="pondMotherObj"
        ref="pondMotherObj"
        class="demo-pondMotherObj"
      >
        <ValidationProvider
          rules="required"
          :name="$tc('Comn_pond', 0)"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$tc('Comn_pond_name', 0)"
            prop="pond"
            :error="errors[0]"
          >
            <er-select
              v-model="pondMotherObj.pond"
              @change="handleChangePond"
              :placeholder="$tc('Comn_pond_name', 0)"
              collapse-tags
              value-key="title"
              :showSelectAll="true"
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
            >
              <template v-for="pond in pondsData">
                <el-option
                  v-if="pond.pond_mothers.length > 0"
                  :key="pond._id"
                  :label="pond.title"
                  :value="pond._id"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="$t('Comn_pondmother_code')"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$t('Comn_pondmother_code')"
            prop="pond_mother_id"
            :error="errors[0]"
          >
            <er-select
              v-model="pondMotherObj.pond_mother_id"
              :placeholder="$t('Comn_pondmother_code')"
              :no-data-text="$t('Comn_no_data_connection')"
              :showSelectAll="true"
              :disabled="disable_field"
              value-key="device_code"
              collapse-tags
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
              @change="handlePmIdChange"
            >
              <template>
                <el-option
                  v-for="pm in selectedPondPms"
                  :key="pm._id"
                  :value="pm"
                  :label="upm__getValue(pm)"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('PM_kg_dispensed')"
          immediate
          :rules="{
            required_if_not: {
              target: ['mode', 'feeder_type'],
              values: pondMotherObj
            },
            max_value: cnstsPMSettings.kg_dispense_time_secs[1],
            min_value: cnstsPMSettings.kg_dispense_time_secs[0]
          }"
          v-slot="{ errors }"
        >
          <el-form-item label="temp" prop="feedlimit" :error="errors[0]">
            <span
              slot="label"
              v-html="$t('PM_kg_dispensed_time_sec_for_pop_up')"
            ></span>
            <!-- v-model="" :precision="0"-->
            <el-input-number
              v-model="pondMotherObj.kg_dispense_time_sec"
              size="small"
              :controls="false"
              :min="0"
              :precision="getPrecision(pondMotherObj)"
              :disabled="columnLevelPermissions('MANAGE_DEVICES', row, 'kgDisTime')"
              @keypress.native="isNumber($event, pondMotherObj)"
              @change="handleKgDispense"
              :placeholder="$t('enter_kg_dispensed_time_sec')"
            ></el-input-number>
          </el-form-item>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('mode')"
          immediate
          :rules="{
            required_if_not: {
              target: ['feeder_type', 'kg_dispense_time_sec'],
              values: pondMotherObj
            }
          }"
          v-slot="{ errors }"
        >
          <el-form-item :label="$t('mode')" prop="mode" :error="errors[0]">
            <er-select
              v-model="pondMotherObj.mode"
              @input="handleMode"
              :placeholder="$t('mode')"
              :no-data-text="$t('Comn_no_data_connection')"
              size="small"
              popper-class="pm-dropdown"
              :disabled="columnLevelPermissions('MANAGE_SCHEDULES', row, 'mode')"
            >
              <el-option
                v-for="(value, key) in pondModesOfOperations"
                :label="$t(value.lang_key)"
                :key="key"
                :value="value.mode"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          :name="$t('feeder-type')"
          immediate
          :rules="{
            required_if_not: {
              target: ['mode', 'kg_dispense_time_sec'],
              values: pondMotherObj
            }
          }"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$t('feeder-type')"
            prop="feeder_type"
            :error="errors[0]"
          >
            <er-select
              v-model="pondMotherObj.feeder_type"
              @input="handleFeederType"
              :placeholder="$t('feeder-type')"
              :no-data-text="$t('Comn_no_data_connection')"
              size="small"
              popper-class="pm-dropdown"
              :disabled="columnLevelPermissions('MANAGE_SCHEDULES', row, '')"
            >
              <el-option
                v-for="item in hopperTypes"
                :label="$t(item.lang_key)"
                :key="item.value"
                :value="item.value"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>

        <div class="errorwithouthighlight">
          <!-- v-bind:class="{ errorCss: errorhighlight }" -->
          <span class="material-icons-outlined"> info </span>
          <span>{{ $t('Atleast_Feed_dispense_time_Mode') }}</span>
        </div>
      </el-form>
    </ValidationObserver>
    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        @click="submitResourceDetails"
        :loading="loading"
        :disabled="isReadOnly || disable_field"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from '@/mixins/errorHandlerMixin';
import errorKeyMapMixin from '@/mixins/errorKeyMapMixin';
import { mapGetters, mapActions } from 'vuex';
import { extend } from 'vee-validate';
import userPreferenceMixin from '@/mixins/userPreferenceMixin';
import { pondMotherSettings as cnstsPMSettings } from '@/constants/settings';
import filtersMixin from '@/mixins/filtersMixin.js';
import {
  required_if,
  required,
  max_value,
  min_value
} from 'vee-validate/dist/rules';
// extend("required", {
//   ...required,
//   message: "{_field_} is required",
// });

export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    userPreferenceMixin,
    filtersMixin
  ],
  props: {
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ''
    }
  },
  data: function() {
    return {
      disable_field: false,
      loading: false,
      errorhighlight: false,
      checkmode: false,
      feedermode: false,
      checkdispensefeed: false,
      cnstsPMSettings,
      pondMotherObj: {
        pond: [],
        pond_mother_id: [],
        kg_dispense_time_sec: '',
        mode: '',
        feeder_type: ''
      },
      pmsData: [],
      modeData: {},
      selectedData: []
    };
  },
  computed: {
    ...mapGetters('user', {
      isReadOnly: 'isReadOnly',
      getPreferredUnits: 'getPreferredUnits',
      getPermissions: 'getPermissions',
      getUserProfile: "getUserProfile"
    }),
    ...mapGetters({
      PondMothers: 'pondmother/getPondMothers',
      mapPondIdPond: 'pond/getMapPondidPond',
      mapStIdSt: 'shrimptalk/getMapStidSt',
      Ponds: 'pond/getPonds',
      getPmIdPm: 'pondmother/getPmIdPm',
      getMapPmIdMode: 'pondmother/getMapPmIdMode',
      getUserId: 'user/getUserId'
    }),
    handlePermissions() {
      return this.getPermissions;
    },
    pondModesOfOperations() {
      if (this.selectedData.length > 0) {
        this.getPondModesOfOperations(this.selectedData);
      } else {
        this.getPondModesOfOperations()
      }
      return this.modeData;
    },
    getPrecision() {
      return (data) => {
        console.log(data);
        if (data?.allow_decimal_for_kg_dispense_time) {
          return 2;
        } else {
          return 0;
        }
      };
    },
    pondsData() {
      return this.Ponds;
    },
    // getDeviceName() {
    //   if (this.getPreferredUnits.device_name === "code") {
    //     return "device_code";
    //   } else {
    //     return "device_title";
    //   }
    // },
    hopperTypes() {
      return {
        PM75: { value: 'PM75', lang_key: 'pm-bin-type.pm75' },
        PM125: { value: 'PM125', lang_key: 'pm-bin-type.pm125' },
        'PM250-S': { value: 'PM250-S', lang_key: 'pm-bin-type.pm250-s' },
        'PM250-C': { value: 'PM250-C', lang_key: 'pm-bin-type.pm250-c' },
        // 'PM250-MAX-S': { value: 'PM250-MAX-S', lang_key: 'pm-bin-type.pm250-max-s' },
        // 'PM250-MAX-C': { value: 'PM250-MAX-C', lang_key: 'pm-bin-type.pm250-max-c' }
      };
    },
    selectedPondPms() {
      const pms = this.pondMotherObj.pond
        .map((pond) => this.mapPondIdPond.get(pond).pond_mothers)
        .flat(1);

      return pms
        .map((x) => {
          return {
            device_code: x.code,
            device_title: x.title || x.code,
            _id: x._id,
            shrimp_talk_id: x.shrimp_talk_id
          };
        })
        .sort((a, b) => {
          return this.$commonUtils.alphaNumericComparator(
            this.upm__getValue(a),
            this.upm__getValue(b),
            this.upm__getChunkSizeByDeviceIdentityKey
          );
        });
    },

    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog('close');
      }
    }
  },
  methods: {
    ...mapActions({
      updatePMSettings: 'pondmother/updatePMSettings'
    }),
    columnLevelPermissions(permission, row, field) {
      if (this.getUserProfile.permissions.length > 0 && this.handlePermissions[permission]) {
        return !this.handlePermissions[permission].UPDATE;
      } else {
        return this.disable_field;
      }
    },
    isNumber(val, data) {
      if (data && data?.allow_decimal_for_kg_dispense_time) {
        if (val.key === ' ') {
          return val.preventDefault();
        }
      } else if (data && !data?.allow_decimal_for_kg_dispense_time) {
        if (val.key === ' ' || val.key === '.') {
          return val.preventDefault();
        }
      }
    },
    handleKgDispense(updtPropVal) {
      if (updtPropVal !== '') {
        this.checkdispensefeed = true;
        this.errorhighlight = false;
      } else {
        this.checkdispensefeed = false;
        if (this.checkmode && this.feedermode) {
          this.errorhighlight = false;
        } else {
          this.errorhighlight = true;
        }
      }
    },
    handleMode(event) {
      const checkStr = event;
      if (checkStr.length) {
        this.checkmode = true;
      }
      this.errorhighlight = false;
    },
    handleFeederType(event) {
      const checkStr = event;
      if (checkStr.length) {
        this.feedermode = true;
      }
      this.errorhighlight = false;
    },
    handleOpenDialog() {
      this.initValidations();
      this.disable_field = true;
      this.checkmode = false;
      this.feedermode = false;
      this.checkdispensefeed = false;
      this.errorhighlight = false;
      this.pondMotherObj = {
        pond: [],
        pond_mother_id: [],
        kg_dispense_time_sec: undefined,
        mode: '',
        feeder_type: ''
      };
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },
    handleCheckAllPMs(value) {
      this.pondMotherObj.pond_mother_id = value ? this.selectedPondPms : [];
    },

    handleChangePond(pondIdArr) {
      if (pondIdArr.length > 0) {
        this.handleCheckAllPMs(true);
        this.disable_field = false;
      } else {
        this.handleCheckAllPMs(false);
      }
    },

    handleCloseDialog(event) {
      this.pondMotherObj = {
        pond: [],
        pond_mother_id: [],
        kg_dispense_time_sec: '',
        mode: '',
        feeder_type: ''
      };
      this.$refs.dialogListForm.reset();
      this.$emit('close_dialog', event);
    },
    initValidations() {
      console.log('cnstsPMSettings', cnstsPMSettings);
      extend('required_if_not', {
        ...required_if,
        params: ['target', 'values'],

        validate: (value, args) => {
          console.log(required_if);
          const target = args.target;
          const values = args.values;
          if (value) {
            return Boolean(value);
          } else {
            if (
              values.feeder_type !== '' ||
              values.kg_dispense_time_sec !== undefined ||
              values.mode !== ''
            ) {
              return true;
            }
            target.length > 0 &&
              target.map((item) => {
                if (values.item) {
                  return Boolean(item);
                } else {
                  return false;
                }
              });
          }
          return false;
        },
        message: ' '
      });

      extend('required', {
        ...required,
        message: this.$t('Comn_field_is_required', {
          field: '{_field_}'
        })
      });
      extend('max_value', {
        ...max_value,
        message: this.$tc('Comn_must_less_than_or_equal', 0, {
          field: '{_field_}',
          other_field: this.cnstsPMSettings.kg_dispense_time_secs[1]
        })
      });
      extend('min_value', {
        ...min_value,
        message: this.$tc('Comn_must_greater_than_or_equal', 0, {
          field: '{_field_}',
          other_field: this.cnstsPMSettings.kg_dispense_time_secs[0]
        })
      });
    },

    async submitResourceDetails() {
      const response = await this.$refs.dialogListForm.validate();

      if (!response) {
        if (
          this.pondMotherObj.mode !== '' ||
          this.pondMotherObj.kg_dispense_time_sec !== '' ||
          this.pondMotherObj.feeder_type !== ''
        ) {
          this.errorhighlight = false;
          return '';
        } else {
          this.errorhighlight = true;
          return '';
        }
      } else if (
        this.pondMotherObj.mode === '' &&
        this.pondMotherObj.kg_dispense_time_sec === '' &&
        this.pondMotherObj.feeder_type === ''
      ) {
        this.errorhighlight = true;
      }
      console.log('this.pondMotherObj', this.pondMotherObj);
      const formattedST = this.pondMotherObj.pond_mother_id.map((pm) => {
        return [
          [this.checkmode, 'mode', 'mode'],
          [this.feedermode, 'hopper_type', 'feeder_type'],
          [this.checkdispensefeed, 'kg_dispense_time', 'kg_dispense_time_sec']
        ].reduce(
          (acc, [isChecked, destinationProp, sourceProp]) => {
            if (isChecked && sourceProp === 'mode') {
              acc[destinationProp] = this.pondMotherObj[sourceProp];
            } else if (isChecked && sourceProp === 'kg_dispense_time_sec') {
              acc[destinationProp] = Number(this.pondMotherObj[sourceProp]);
            } else if (isChecked && sourceProp === 'feeder_type') {
              acc[destinationProp] = this.pondMotherObj[sourceProp];
            }
            return acc;
          },
          { pond_mother_id: pm._id }
        );
      });

      this.loading = true;
      try {
        await this.updatePMSettings(formattedST);
        this.$notify({
          title: this.$t('Usrs_success_msg'),
          message: this.$t('PM_upd_success'),
          duration: 5000,
          type: 'success'
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$emit('close_dialog', false, 'retrieveData');
        this.loading = false;
      }
    },
    handlePmIdChange(data) {
      this.selectedData = data;
      this.getPondModesOfOperations(data);
    },
    getPondModesOfOperations(data = []) {
      const PmsDataArr = data.length > 0 ? data : this.selectedPondPms
      var STDataArr = [];
        PmsDataArr.forEach(pm => {
          if (pm.shrimp_talk_id !== undefined) {
            STDataArr.push(this.mapStIdSt.get(pm.shrimp_talk_id).block_shrimp_talk)
          }
        });
      this.modeData = STDataArr.includes(true) ? {
          FARMER: { mode: 'SCHEDULE', lang_key: 'PM_schedule' },
          MANUAL: { mode: 'BASIC', lang_key: 'PM_sch_mode4' }
        } : {
        FARMER: { mode: 'SCHEDULE', lang_key: 'PM_schedule' },
        MANUAL: { mode: 'BASIC', lang_key: 'PM_sch_mode4' },
        SHRIMP_TALK: { mode: 'AUTOMATIC', lang_key: 'PM_automatic' }
      };
      return this.modeData;
    },
  },
  mounted() {
    this.initValidations();
  }
};
</script>

<style lang="scss">
.pondmother_container {
  .toolbar-layout {
    justify-content: flex-end !important;
  }
  .el-dialog {
    @include responsiveProperty(width, 35%, 35%, 35%);
  }
  .el-dialog__body {
    padding: 15px 15px;
    height: 275px !important;
  }

  .el-dialog .el-dialog__body {
    @include responsiveProperty(min-height, 220px, 220px, 250px);
  }
  .select-units,
  .select-resource-type {
    width: 100%;
  }
  // .el-input-number {
  //   width: 100%;
  // }

  .el-select .el-tag.el-tag--info .el-tag__close {
    display: inline-block !important;
    pointer-events: all !important;
  }

  .el-input--mini {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    // width: 275px;
  }
}
.el-form-item {
  label {
    // width: 39%;
    // max-width: 39%;
  }
}

.pondmother_action_dialog {
  border-radius: 10px;
  .errorwithouthighlight {
    display: flex;
    word-break: break-word !important;
    // align-items: center;
    justify-content: center;
    @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
    .material-icons-outlined {
      margin-top: -1px !important;
      margin-right: 4px;
      word-break: break-word !important;
    }
  }
  .errorCss {
    color: #f56c6c;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
  }

  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 200px, 200px, 200px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 177px, 215px, 265px);
    line-height: 20px;
    display: contents;
  }
  .el-form-item__error {
    top: auto;
    @include responsiveProperty(left, 193px, 230px, 281px);
  }
  .el-form-item--small .el-form-item__error {
    @include responsiveProperty(margin-top, 31px, 31px, 41px);
  }
}
</style>
